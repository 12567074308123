import React from "react"
import Layout from "../components/Layout"
import { Link } from "gatsby"
import Expensify from "../components/Images/Expensify"
import Indecision from "../components/Images/Indecision"
import HIMYM from "../components/Images/HIMYM"
import Parakeet from "../components/Images/Parakeet"
import { Helmet } from "react-helmet"
import "./applications.styles.scss"

const Applications = ({ data }) => {
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>William Cheshier</title>
        <link rel="canonical" href="https://williamcheshier.com/applications" />
      </Helmet>
      <div className="app-wrapper">
        <h1 className="app-header">Applications</h1>
        <div className="app-row">
          <div className="app-column">
            <a
              href="http://www.flyparakeet.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Parakeet />
            </a>
          </div>
          <div className="text-column">
            <h1 className="project-title">Parakeet</h1>
            <p className="project-text">
              Full stack application utilizing React, Redux, Hooks, Node.js,
              Express, MongoDb, and Styled Components. This web application is
              designed to help developer teams communicate more effectively on
              their projects. User authentication and user roles allow teams to
              store their projects and make live comments and edits to the
              specific pages they want changed. Currently in production. I am
              serving as a Jr. Developer on this team.
            </p>

            <p className="project-text">
              Tech: React | Redux | Node.js | Express | MongoDB | Styled
              Components |{" "}
            </p>
            <p className="project-text">Currently in production</p>
            <a
              className="app-links"
              href="http://www.flyparakeet.com/"
              target="_blank"
            >
              Live application
            </a>
          </div>
        </div>
        <div className="app-row">
          <div className="app-column">
            <a
              href="https://will-s-expensify-app.herokuapp.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Expensify />
            </a>
          </div>
          <div className="text-column">
            <h1 className="project-title">Expensify</h1>
            <p className="project-text">
              A budget applciation designed to help you keep track of your
              expenses. Built using React, Redux, Node.js, Express, SCSS,
              testing with Jest, and Firebase. Application stores user data and
              allows for easy Google authentication process. Give it a try!
            </p>
            <p className="project-text">Completed: November 2018</p>
            <p className="project-text">
              Tech: React | Redux | Jest | Node.js | Express | Firebase | SCSS{" "}
            </p>
            <div className="app-link-row">
              <a
                className="app-links"
                href="https://will-s-expensify-app.herokuapp.com/"
                target="_blank"
              >
                Live application
              </a>
              <a
                className="app-links"
                href="https://github.com/wchesh24/Will-s-Expensify-App"
                target="_blank"
              >
                Repo
              </a>
            </div>
          </div>
        </div>
        <div className="app-row">
          <div className="app-column">
            <a
              href="https://will-decision-app.herokuapp.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Indecision />
            </a>
          </div>
          <div className="text-column">
            <h1 className="project-title">Indecision</h1>
            <p className="project-text">
              Confused about where to eat tonight? Use the Indecision
              application! Designed to make your life easier by randomly
              selecting a list of items to make the decision for you. This
              application utilized React, Redux, Node.js, Express, and SCSS.
            </p>
            <p className="project-text">Completed: October 2018</p>
            <p className="project-text">
              Tech: React | Redux | Node.js | Express | SCSS{" "}
            </p>
            <div className="app-link-row">
              <a
                className="app-links"
                href="https://will-decision-app.herokuapp.com/"
                target="_blank"
              >
                Live application
              </a>
              <a
                className="app-links"
                href="https://github.com/wchesh24/will-decision-app"
                target="_blank"
              >
                Repo
              </a>
            </div>
          </div>
        </div>
        <div className="app-row">
          <div className="app-column">
            <a
              href="https://how-i-met-your-mother-quiz-app.herokuapp.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <HIMYM />
            </a>
          </div>
          <div className="text-column">
            <h1 className="project-title" style={{ fontSize: "2.5rem" }}>
              How I Met Your Mother Quiz App
            </h1>
            <p className="project-text">
              A fan of How I Met Your Mother? Play this fun quiz built as a fun
              project! A list of 10 questions only true HIMYM fans will know!
              Application built using JavaScript, jQuery, CSS, and HTML5 with
              some fun animations for user experience. Test your knowledge now!
            </p>
            <p className="project-text">Completed: January 2020</p>
            <p className="project-text">
              Tech: JavaScript | jQuery | HTML5 | CSS3{" "}
            </p>
            <div className="app-link-row">
              <a
                className="app-links"
                href="https://how-i-met-your-mother-quiz-app.herokuapp.com/"
                target="_blank"
              >
                Live application
              </a>
              <a
                className="app-links"
                href="https://github.com/wchesh24/how-i-met-your-mother-javascript-quiz"
                target="_blank"
              >
                Repo
              </a>
            </div>
          </div>
        </div>
        <Link className="website-link" to="/websites">
          <h2>Websites</h2>
        </Link>
      </div>
    </Layout>
  )
}

export default Applications
// {data.allFile.edges.map(image => (
//   <Img
//     className="images"
//     key={image.id}
//     fixed={image.node.childImageSharp.fixed}
//   />
// ))}
